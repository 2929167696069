import React from 'react';

import { IPostCard, PostCard } from './PostCard';

import style from './PostGrid.scss';

import cn from 'classnames';

interface IProps {
  posts: Array<{
    node: IPostCard;
  }>;
}

export const PostGrid = ({ posts }: IProps) => (
  <>
    <div className={cn('container', style.container)}>
      <div className={style.default}>
        {posts.map(({ node }) => (
          <PostCard key={node.id} post={node} />
        ))}
      </div>
    </div>
  </>
);
