import React from 'react';

import style from './PostStats.scss';

import { formatPublishedAtDate } from 'utils/helpers';

interface IProps {
  publishedAt: string;
  readTime: string;
}

export const PostStats = (props: IProps) => {
  return (
    <div className={style.default}>
      <span>{formatPublishedAtDate(props.publishedAt)}</span>
      <span className={style.dot}>&#183;</span>
      <span>{props.readTime}</span>
    </div>
  );
};
