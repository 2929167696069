import React from 'react';

import { PostStats } from './PostStats';

import style from './PostCard.scss';

import { readingTime } from '@tryghost/helpers';
import cn from 'classnames';
import { Link } from 'gatsby';

export interface IPostCard {
  id: string;
  slug: string;
  title: string;
  feature_image: string;
  featured: boolean;
  excerpt: string;
  primary_author: {
    name: string;
    profile_image: string;
  };
  published_at: string;
}

interface IProps {
  className?: string;
  post: IPostCard;
  imageClassName?: string;
}

export const PostCard = ({ className, post, imageClassName }: IProps) => {
  const url = `/${post.slug}/`;

  return (
    <Link to={url} className={cn(className, style.base)}>
      <header>
        {post.feature_image && (
          <div
            className={cn(style.image, imageClassName)}
            style={{
              backgroundImage: `url(${post.feature_image})`,
            }}
          />
        )}
        <div className={style.headerContent}>
          <h2 className={style.title}>{post.title}</h2>
        </div>
      </header>
      <section className={style.excerpt}>{post.excerpt.replace(/\[[^\\]+\]/, '')}</section>
      <footer className={style.footer}>
        <PostStats publishedAt={post.published_at} readTime={readingTime(post)} />
      </footer>
    </Link>
  );
};
