import React from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { PostGrid } from '../components/PostGrid';

import style from '../styles/pages/blog.scss';

import { graphql } from 'gatsby';

interface IIndexProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

const Index = ({ data, pageContext, location }: IIndexProps) => {
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData location={location} data={{}} />
      <Layout isHome headerClassName={style.header}>
        <section className={style.blog}>
          <PostGrid posts={posts} />
          <div className="container">
            <Pagination pageContext={pageContext} />
          </div>
        </section>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
